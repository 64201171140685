import React, { useEffect } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { CALENDER_TYPE } from '../../utils/dropdownOptions';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikTime from '../../components/formik/FormikTime';
import * as Yup from 'yup';
import moment from 'moment';
import { toast } from 'react-toastify';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikCheckbox from '../../components/formik/FormikCheckbox';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import { X } from 'react-feather';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';

const EventForm = ({
    isModalOpen,
    setIsModalOpen,
    selectedEvent,
    setSelectedEvent,
    isEditing,
    fetchEvents,
    events,
}) => {

    const computeInitialValue = (data) => {
        if (data) {
            if (data.action === 'click' && data.title) {
                return {
                    ...data,
                    title: data?.title,
                    type: data?.type,
                    allDay: data?.allDay,
                    start: data?.start,
                    end: data?.start,
                    startTime: moment(data?.start).toDate(),
                    endTime: moment(data?.end).toDate(),
                    allDay: data?.allDay,
                    fullDayEvents: [],
                    description: data?.description,
                };
            }
            if (data.action === 'click' && !data.title) {
                return {
                    ...data,
                    title: data?.title,
                    type: data?.type,
                    allDay: data?.allDay,
                    start: data?.start,
                    end: data?.start,
                    startTime: null,
                    endTime: null,
                    allDay: false,
                    fullDayEvents: [],
                    description: data?.description,
                };
            }
            return {
                ...data,
                title: data?.title,
                type: data?.type,
                allDay: data?.allDay,
                start: data?.start,
                end: data?.end,
                startTime: moment(data?.start).toDate(),
                endTime: moment(data?.end).toDate(),
                allDay: data?.allDay ? data?.allDay : false,
                fullDayEvents: [],
                description: data?.description,
            };
        } else {
            return {
                title: '',
                type: '',
                allDay: false,
                start: null,
                end: null,
                startTime: '',
                endTime: '',
                fullDayEvents: [],
                priority: '',
                description:'',
            };
        }
    };

    const updateStartEndTimes = (values) => {
        const startDate = new Date(values.start);
        const startTime = new Date(values.startTime);
        const endDate = new Date(values.end);
        const endTime = new Date(values.endTime);

        startDate.setHours(startTime.getHours());
        startDate.setMinutes(startTime.getMinutes());
        startDate.setSeconds(startTime.getSeconds());

        endDate.setHours(endTime.getHours());
        endDate.setMinutes(endTime.getMinutes());
        endDate.setSeconds(endTime.getSeconds());

        return {
            ...values,
            start: startDate,
            end: endDate,
            // startTime : startDate,
            // endTime : endTime,
        };
    };

    const calculatePriority = (type) => {
        switch (type) {
            case 'holiday':
                return 30;
            case 'leave':
                return 20;
            case 'meeting':
                return 10;
            case 'nonWorkingHours':
                return 0;
            default:
                return 0;
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: computeInitialValue(selectedEvent),
        validationSchema: Yup.object({
            title: Yup.string().label('Title').required(),
            type: Yup.string().label('Type').required(),
            allDay: Yup.boolean().label('All Day').required(),
        }),
        onSubmit: async (values, { resetForm }) => {
            if (values?.allDay) {
                if (!isEditing) {
                    if (!values?.fullDayEvents?.length) {
                        toast.error('Event date/dates not selected');
                        return;
                    }
                    for (let i = 0; i < values?.fullDayEvents?.length; i++) {
                        let eventDate = values?.fullDayEvents[i].date;
                        const startNewDate = moment(eventDate)
                            .startOf('day')
                            .toDate();
                        const endNewDate = moment(eventDate)
                            .endOf('day')
                            .toDate();

                        const isSunday = moment(startNewDate).day() === 0;
                        if (isSunday) {
                            toast.error('Cannot create events on Sundays.');
                            return;
                        }

                        const isHoliday = events.some(
                            (ele) =>
                                moment(startNewDate).isSame(ele.start, 'day') &&
                                ele.type === 'holiday'
                        );
                        if (isHoliday) {
                            toast.error(
                                'This day is already a holiday. No events can be created on holidays.'
                            );
                            return;
                        }

                        const obj = {
                            ...values,
                            start: startNewDate,
                            end: endNewDate,
                            priority: calculatePriority(values?.type),
                        };

                        const onlyEvents = events.filter(
                            (ele) =>
                                ele.type !== 'holiday' &&
                                ele.type !== 'lunchBreak' &&
                                ele.allDay === 'true'
                        );
                        if (!isEditing) {
                            const isAnyMeeting = onlyEvents.some(
                                (planEvent) =>
                                    moment(obj?.start).isBetween(
                                        moment(planEvent.start),
                                        moment(planEvent.end),
                                        undefined,
                                        '[)'
                                    ) ||
                                    moment(obj?.end).isBetween(
                                        moment(planEvent.start),
                                        moment(planEvent.end),
                                        undefined,
                                        '(]'
                                    )
                            );
                            if (isAnyMeeting) {
                                toast.error(
                                    'At same time an event is already planned.'
                                );
                                return;
                            }
                        }
                        try {
                            if (!isEditing) {
                                const resp = await authAxiosInstance.post(
                                    `/calenderEvent`,
                                    obj
                                );
                                if (resp?.data?.success) {
                                    fetchEvents();
                                }
                            }
                            if (isEditing) {
                                const updateOne = await authAxiosInstance.patch(
                                    `/calenderEvent`,
                                    obj
                                );
                                if (updateOne?.data?.success) {
                                    fetchEvents();
                                }
                            }
                            toast.success(
                                isEditing ? 'Event Edited' : 'Event created'
                            );
                            resetForm();
                            setIsModalOpen(false);
                        } catch (error) {
                            console.log('error', error);
                            toast.error(
                                isEditing
                                    ? 'Failed to edit event'
                                    : 'Failed to Create event'
                            );
                        }
                    }
                } else {
                    const newStart = moment(values.start).toDate();
                    const newEnd = moment(values.end).toDate();
                    const isSunday = moment(newStart).day() === 0;
                    if (isSunday) {
                        toast.error('Cannot create events on Sundays.');
                        return;
                    }                    
                    const updatedObj = updateStartEndTimes({
                        ...values,
                        start: newStart,
                        end: newEnd,
                        priority: calculatePriority(values?.type),
                    });
                    const lunchBreaks = events.filter(
                        (ele) => ele.type === 'lunchBreak'
                    );
                    const isDuringLunch = lunchBreaks.some(
                        (breakEvent) =>
                            moment(updatedObj?.start).isBetween(
                                moment(breakEvent.start),
                                moment(breakEvent.end),
                                undefined,
                                '[)'
                            ) ||
                            moment(updatedObj?.end).isBetween(
                                moment(breakEvent.start),
                                moment(breakEvent.end),
                                undefined,
                                '(]'
                            )
                    );
                    if (isDuringLunch) {
                        toast.error('Cannot create events during lunch breaks.');
                        return;
                    }
                    try {
                        const updateOne = await authAxiosInstance.patch(
                            `/calenderEvent`,
                            updatedObj
                        );
                        if (updateOne?.data?.success) {
                            fetchEvents();
                        }
                        toast.success('Event Edited');
                        resetForm();
                        setIsModalOpen(false);
                    } catch (error) {
                        console.log('error', error);
                        toast.error('Failed to edit event');
                    }
                }
            } else if (!values?.allDay) {
                const newStart = moment(values.start).toDate();
                const newEnd = moment(values.end).toDate();

                const isSunday = moment(newStart).day() === 0;
                if (isSunday) {
                    toast.error('Cannot create events on Sundays.');
                    return;
                }
                const isHoliday = events.some(
                    (ele) =>
                        moment(newStart).isSame(ele.start, 'day') &&
                        ele.type === 'holiday'
                );
                if (isHoliday) {
                    toast.error(
                        'This day is already a holiday. No events can be created on holidays.'
                    );
                    return;
                }
                const updatedObj = updateStartEndTimes({
                    ...values,
                    start: newStart,
                    end: newEnd,
                    priority: calculatePriority(values?.type),
                });
                const lunchBreaks = events.filter(
                    (ele) => ele.type === 'lunchBreak'
                );
                const isDuringLunch = lunchBreaks.some(
                    (breakEvent) =>
                        moment(updatedObj?.start).isBetween(
                            moment(breakEvent.start),
                            moment(breakEvent.end),
                            undefined,
                            '[)'
                        ) ||
                        moment(updatedObj?.end).isBetween(
                            moment(breakEvent.start),
                            moment(breakEvent.end),
                            undefined,
                            '(]'
                        )
                );
                if (isDuringLunch) {
                    toast.error('Cannot create events during lunch breaks.');
                    return;
                }
                const onlyEvents = events.filter(
                    (ele) => ele.type !== 'holiday' && ele.type !== 'lunchBreak'
                );
                if (!isEditing) {
                    const isAnyMeeting = onlyEvents.some(
                        (planEvent) =>
                            moment(updatedObj?.start).isBetween(
                                moment(planEvent.start),
                                moment(planEvent.end),
                                undefined,
                                '[)'
                            ) ||
                            moment(updatedObj?.end).isBetween(
                                moment(planEvent.start),
                                moment(planEvent.end),
                                undefined,
                                '(]'
                            )
                    );
                    if (isAnyMeeting) {
                        toast.error(
                            'At same time an event is already planned.'
                        );
                        return;
                    }
                }

                try {
                    if (!isEditing) {
                        const resp = await authAxiosInstance.post(
                            `/calenderEvent`,
                            updatedObj
                        );
                        if (resp?.data?.success) {
                            fetchEvents();
                        }
                    }
                    if (isEditing) {
                        const updateOne = await authAxiosInstance.patch(
                            `/calenderEvent`,
                            updatedObj
                        );
                        if (updateOne?.data?.success) {
                            fetchEvents();
                        }
                    }
                    toast.success(isEditing ? 'Event Edited' : 'Event created');
                    resetForm();
                    setIsModalOpen(false);
                } catch (error) {
                    console.log('error', error);
                    toast.error(
                        isEditing
                            ? 'Failed to edit event'
                            : 'Failed to Create event'
                    );
                }
            }
        },
    });

    useEffect(() => {
        if (!isModalOpen) {
            setSelectedEvent(null);
            formik.resetForm();
        }
    }, [isModalOpen]);

    return (
        <ModalBasic
            modalOpen={isModalOpen}
            setModalOpen={setIsModalOpen}
            title={isEditing ? 'Edit Event' : 'Add Event'}
        >
            {isEditing ? (
                <form
                    className="flex flex-col gap-4 p-4"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="flex flex-col md:flex-row items-center gap-4">
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                label="Title"
                                name="title"
                            />
                        </div>
                        <div className="w-full">
                            <FormikSelectGroup
                                formik={formik}
                                label="Type"
                                name="type"
                                options={CALENDER_TYPE}
                            />
                        </div>
                    </div>
                    <div>
                        <FormikCheckbox
                            formik={formik}
                            label="All Day"
                            name="allDay"
                        />
                    </div>

                    <div className="flex flex-col md:flex-row items-center gap-4">
                        <div className="w-full">
                            <FormikInputDateGroup
                                formik={formik}
                                label="Start"
                                name="start"
                                onChange={(selectedDate) => {
                                    formik.setFieldValue('start', selectedDate);
                                    formik.setFieldValue('end', selectedDate);
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputDateGroup
                                formik={formik}
                                label="End"
                                name="end"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-4">
                        <div className="w-full">
                            <FormikTime
                                formik={formik}
                                label="Start Time"
                                name="startTime"
                                readOnly={
                                    formik?.values?.allDay === true
                                        ? true
                                        : false
                                }
                            />
                        </div>
                        <div className="w-full">
                            <FormikTime
                                formik={formik}
                                label="End Time"
                                name="endTime"
                                readOnly={
                                    formik?.values?.allDay === true
                                        ? true
                                        : false
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <FormikTextareaGroup 
                            formik={formik}
                            label='Description'
                            name='description'
                        />
                    </div>
                    <div className="mt-4 flex flex-row gap-4">
                        <PrimaryButton
                            type="submit"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            Update
                        </PrimaryButton>
                        <DangerButton
                            type='button'
                            onClick={async(e)=>{
                                e.stopPropagation();
                                const confirm = window.confirm(
                                    'Are you sure you want to delete this event ?'
                                )
                                if(confirm){
                                    console.log('event data', selectedEvent)
                                    const resp = await authAxiosInstance.delete(
                                        `/calenderEvent/${selectedEvent?._id}`
                                    )
                                    if(resp?.data?.success){
                                        fetchEvents();
                                    }
                                    toast.success('Event Deleted');
                                    setIsModalOpen(false)
                                }
                            }}
                        >
                            Delete This Event
                        </DangerButton>
                    </div>
                </form>
            ) : (
                <form
                    className="flex flex-col gap-4 p-4"
                    onSubmit={formik.handleSubmit}
                >
                    <div className="flex flex-col md:flex-row items-center gap-4">
                        <div className="w-full">
                            <FormikInputGroup
                                formik={formik}
                                label="Title"
                                name="title"
                            />
                        </div>
                        <div className="w-full">
                            <FormikSelectGroup
                                formik={formik}
                                label="Type"
                                name="type"
                                options={CALENDER_TYPE}
                            />
                        </div>
                    </div>
                    <div>
                        <FormikCheckbox
                            formik={formik}
                            label="All Day"
                            name="allDay"
                        />
                    </div>
                    {formik?.values?.allDay === false && (
                        <>
                            <div className="flex flex-col md:flex-row items-center gap-4">
                                <div className="w-full">
                                    <FormikInputDateGroup
                                        formik={formik}
                                        label="Start"
                                        name="start"
                                        onChange={(selectedDate) => {
                                            formik.setFieldValue(
                                                'start',
                                                selectedDate
                                            );
                                            formik.setFieldValue(
                                                'end',
                                                selectedDate
                                            );
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormikInputDateGroup
                                        formik={formik}
                                        label="End"
                                        name="end"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row items-center gap-4">
                                <div className="w-full">
                                    <FormikTime
                                        formik={formik}
                                        label="Start Time"
                                        name="startTime"
                                    />
                                </div>
                                <div className="w-full">
                                    <FormikTime
                                        formik={formik}
                                        label="End Time"
                                        name="endTime"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {formik?.values?.allDay === true && (
                        <div>
                            <FormikProvider value={formik}>
                                <FieldArray
                                    name="fullDayEvents"
                                    render={(arrayHelpers) => {
                                        return (
                                            <div>
                                                <div className="my-2">
                                                    {formik.values.fullDayEvents.map(
                                                        (ele, i) => (
                                                            <div
                                                                key={i}
                                                                className="flex  gap-2  items-center"
                                                            >
                                                                <FormikInputDateGroup
                                                                    formik={
                                                                        formik
                                                                    }
                                                                    label="Date"
                                                                    name={`fullDayEvents.${i}.date`}
                                                                />
                                                                <X
                                                                    className="text-red-500 "
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        arrayHelpers.remove(
                                                                            i
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                <div>
                                                    <SecondaryButton
                                                        onClick={() => {
                                                            arrayHelpers.push(
                                                                ''
                                                            );
                                                        }}
                                                    >
                                                        Add Date
                                                    </SecondaryButton>
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            </FormikProvider>
                        </div>
                    )}
                    <div>
                        <FormikTextareaGroup 
                            formik={formik}
                            label='Description'
                            name='description'
                        />
                    </div>
                    <div className="mt-4">
                        <PrimaryButton
                            type="submit"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            Add
                        </PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default EventForm;
