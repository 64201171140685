import DatePicker from 'react-datepicker';
import _ from 'lodash';
import moment from 'moment';
const FormikTime = ({
    formik,
    label = '',
    required = false,
    name,
    dateFormat = 'dd/MM/yyyy',
    ...props
}) => {
    const generateTimeIntervals = () => {
        const times = [];
        let currentTime = moment().startOf('day').hour(8).minutes(0); // Start at 8:00 AM
        const endTime = moment().startOf('day').hour(22).minutes(30); // End at 10:30 PM

        while (currentTime <= endTime) {
            times.push(currentTime.toDate());
            currentTime = currentTime.add(30, 'minutes'); 
        }

        return times;
    };
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="w-full flex flex-col"
        >
            <label className="block text-sm font-medium mb-1" htmlFor={name}>
                {label} {required && <span className="text-rose-500">*</span>}
            </label>
            <DatePicker
                selected={_.at(formik.values, name)[0]}
                onChange={(date) => {
                    // console.log(new Date(date).toTimeString(), date, 'date');
                    formik.setFieldValue(name, date);
                }}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                includeTimes={generateTimeIntervals()}
                dateFormat="h:mm aa"
                className='border-gray-200 rounded-sm w-full'
                {...props}
            />
        </div>
    );
};

export default FormikTime;
