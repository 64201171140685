import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { fetchCustomers } from '../../app/reducers/Customer/customerSlice';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import moment from 'moment';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import {
    createInvoice,
    getInvoice,
} from '../../app/reducers/Invoice/invoice.slice';
import QueryString from 'qs';
import { useNavigate } from 'react-router-dom';
import {
    fetchPurchaseOrders,
    getBills,
    getPurchaseOrder,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { customIDs } from '../../utils/customIds';
import { authAxiosInstance } from '../../utils/axiosConfig';

const CreateInvoice = () => {
    const dispatch = useDispatch();
    const { purchase_custom_buyerName } = customIDs;
    const { loading } = useSelector(getInvoice);
    const navigate = useNavigate();
    const { purchaseOrder, bills } = useSelector(getPurchaseOrder);
    const [hasAmountError, setHasAmountError] = useState('notComputed');
    const [genLoading, setGenLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            customer: '',
            customerName: '',
            customerPhone: '',
            amount: '',
            from: '',
            to: '',
            gst: '',
            date: new Date(),
        },
        validationSchema: Yup.object({
            customer: Yup.string().required(),
            amount: Yup.number().required(),
            from: Yup.mixed().required(),
            to: Yup.mixed().required(),
            date: Yup.mixed(),
            gst: Yup.string(),
        }),
        onSubmit: async (values) => {
            let poUrlData = {
                customer: values.customer,
                from: moment(values.from).format('YYYY-MM-DD'),
                to: moment(values.to).format('YYYY-MM-DD'),
            };
            let poString = QueryString.stringify(poUrlData);
            let invoiceData = {
                customer_id: values.customer,
                payment_terms: 45,
                payment_terms_label: 'Net 45',
                date: moment(values.date).format('YYYY-MM-DD'),
                due_date: moment(values.date)
                    .add(45, 'days')
                    .format('YYYY-MM-DD'),
                line_items: [
                    {
                        item_order: 1,
                        item_id: customIDs.invoice_item_id,
                        rate: values.amount,
                        name: 'Commission on grey nylon fabrics',
                        description: `From ${moment(values.from).format(
                            'DD MMM YYYY'
                        )} to ${moment(values.to).format('DD MMM YYYY')}`,
                        quantity: '1.00',
                        discount: '0%',
                        account_id: customIDs.invoice_item_account_id,
                        item_custom_fields: [
                            {
                                label: 'Qty Taka',
                                value: '1',
                            },
                            {
                                label: 'Unit Rate',
                                value: 1,
                            },
                            {
                                label: 'Vendor Name',
                                value: 'No',
                            },
                        ],
                        hsn_or_sac: '996111',
                    },
                ],
                gst_no: values.gst,
                tds_tax_id: customIDs.invoice_tds_tax_id,
                custom_fields: [
                    {
                        value: '0',
                        customfield_id: customIDs.invoice_rate_calculation,
                    },
                    {
                        value: `From ${moment(values.from).format(
                            'DD MMM YYYY'
                        )} to ${moment(values.to).format('DD MMM YYYY')}`,
                        customfield_id: customIDs.invoice_statment_period,
                    },
                    {
                        value: '0',
                        customfield_id: customIDs.invoice_chalan_number,
                    },
                    {
                        value: values.customerPhone,
                        customfield_id: customIDs.invoice_mobile_number,
                    },
                    {
                        value: `https://admin.kukrejabullsagency.in/poReport?${poString}`,
                        customfield_id: customIDs.invoice_po_report_url,
                    },
                    {
                        value: `https://admin.kukrejabullsagency.in/billReport?${poString}`,
                        customfield_id: customIDs.invoice_bill_report_url,
                    },
                ],
            };
            let invoice = await dispatch(createInvoice(invoiceData));
            setHasAmountError('notComputed');
            if (invoice?.payload?.data?.hasNotice) {
                alert(
                    'please not user has pending invoices and notice is generated for this user'
                );
            }
        },
    });

    useEffect(async () => {
        dispatch(fetchCustomers({ contact_type: 'customer' }));
    }, []);

    return (
        <div className="p-4">
            {loading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="grid grid-cols-2 gap-4 w-full"
                >
                    <h1 className="text-lg font-bold col-span-2">
                        Create Invoice
                    </h1>
                    <FormikAsyncSelect
                        name="customer"
                        label="Customer"
                        formik={formik}
                        getOptions={async (value) => {
                            const action = await dispatch(
                                fetchCustomers({
                                    search: value,
                                    contact_type: 'customer',
                                })
                            );
                            const serverResp = action?.payload?.contacts.map(
                                (ele) => ({
                                    label: ele.contact_name,
                                    value: `${ele.contact_id}/${ele.gst_no}/${ele.mobile}/${ele.cf_vendor_mobile_number}`,
                                })
                            );
                            return serverResp;
                        }}
                        onChange={(option) => {
                            console.log(option.value);
                            const id = option.value.split('/')[0];
                            const gst = option.value.split('/')[1];
                            const mobile = option.value.split('/')[2];
                            const vendorMobile = option.value.split('/')[3];
                            console.log(vendorMobile, 'check');
                            formik.setFieldValue('customer', id);
                            formik.setFieldValue('gst', gst);
                            formik.setFieldValue('customerName', option.label);
                            formik.setFieldValue('customerPhone', mobile);
                            if (vendorMobile !== 'undefined') {
                                formik.setFieldValue(
                                    'vendorMobileNo',
                                    vendorMobile
                                );
                            }
                        }}
                    />
                    <FormikInputDateGroup
                        name="from"
                        label="From"
                        formik={formik}
                        required
                    />

                    <FormikInputDateGroup
                        name="to"
                        label="To"
                        formik={formik}
                        required
                    />
                    <FormikInputGroup
                        name="amount"
                        fullWidth={true}
                        type="number"
                        label="Amount"
                        formik={formik}
                        required
                    />
                    {formik.values.amount && (
                        <FormikInputDateGroup
                            name="date"
                            label="Date"
                            formik={formik}
                            required
                        />
                    )}
                    <div className="flex gap-4">
                        {hasAmountError === 'notComputed' &&
                            formik.values.customer &&
                            formik.values.from &&
                            formik.values.to && (
                                <PrimaryButton
                                    onClick={async () => {
                                        setGenLoading(true);

                                        let vendorData;
                                        if (formik.values.vendorMobileNo) {
                                            const data = {
                                                contact_type: 'vendor',
                                            };
                                            data['phone_contains'] =
                                                formik.values.vendorMobileNo;
                                            let stringData =
                                                QueryString.stringify(data);
                                            let respData =
                                                await authAxiosInstance.get(
                                                    `/purchase-orders/search/vendor?${stringData}`
                                                );
                                            vendorData =
                                                respData?.data?.data?.contacts;
                                            formik.setFieldValue(
                                                'vendorId',
                                                vendorData[0]?.contact_id
                                            );
                                            if (vendorData.length > 1) {
                                                return alert(
                                                    'Multiple Vendor Exists..'
                                                );
                                            }
                                        }

                                        const data = {
                                            date_start: moment(
                                                formik.values.from
                                            ).format('YYYY-MM-DD'),
                                            date_end: moment(
                                                formik.values.to
                                            ).format('YYYY-MM-DD'),
                                        };
                                        if (
                                            formik.values.vendorMobileNo &&
                                            vendorData.length > 0
                                        ) {
                                            data['vendor_id'] =
                                                vendorData[0]?.contact_id;
                                        } else {
                                            data[
                                                `custom_field_${purchase_custom_buyerName}_contains`
                                            ] = formik.values.customerName;
                                        }

                                        let billData = {
                                            date_start: moment(
                                                formik.values.from
                                            ).format('YYYY-MM-DD'),
                                            date_end: moment(
                                                formik.values.to
                                            ).format('YYYY-MM-DD'),
                                        };

                                        if (
                                            formik.values.vendorMobileNo &&
                                            vendorData.length > 0
                                        ) {
                                            billData['vendor_id'] =
                                                vendorData[0]?.contact_id;
                                        } else {
                                            billData[
                                                `custom_field_${customIDs.purchase_bill_custom_customer_name}_contains`
                                            ] = formik.values.customerName;
                                        }

                                        const poDetail = await dispatch(
                                            fetchPurchaseOrders(data)
                                        );
                                        const billDetail = await dispatch(
                                            getBills(billData)
                                        );

                                        let purchaseOrders =
                                            poDetail.payload?.purchaseorders;
                                        let addPurchaseOrdersAmount = [];
                                        for (
                                            let i = 0;
                                            i < purchaseOrders?.length;
                                            i++
                                        ) {
                                            if (formik.values.vendorMobileNo) {
                                                if (
                                                    purchaseOrders[
                                                        i
                                                    ]?.cf_vendor_commission_1
                                                        ?.toLowerCase()
                                                        ?.trim() == 'yes'
                                                ) {
                                                    addPurchaseOrdersAmount = [
                                                        ...addPurchaseOrdersAmount,
                                                        {
                                                            ...purchaseOrders[
                                                                i
                                                            ],
                                                        },
                                                    ];
                                                }
                                            } else {
                                                if (
                                                    purchaseOrders[
                                                        i
                                                    ]?.cf_vendor_commission_1
                                                        ?.toLowerCase()
                                                        ?.trim() !== 'yes'
                                                ) {
                                                    addPurchaseOrdersAmount = [
                                                        ...addPurchaseOrdersAmount,
                                                        {
                                                            ...purchaseOrders[
                                                                i
                                                            ],
                                                        },
                                                    ];
                                                }
                                            }
                                        }
                                        console.log(
                                            addPurchaseOrdersAmount,
                                            'checking noq po'
                                        );

                                        //Bill Amount Adding
                                        let billOrders =
                                            billDetail.payload?.bills;
                                        let updateBillOrderDetails = [];
                                        for (
                                            let i = 0;
                                            i < billOrders?.length;
                                            i++
                                        ) {
                                            if (formik.values.vendorMobileNo) {
                                                if (
                                                    billOrders[
                                                        i
                                                    ]?.cf_vendor_commission
                                                        ?.toLowerCase()
                                                        ?.trim() === 'yes'
                                                ) {
                                                    updateBillOrderDetails = [
                                                        ...updateBillOrderDetails,
                                                        {
                                                            ...billOrders[i],
                                                        },
                                                    ];
                                                }
                                            } else {
                                                if (
                                                    billOrders[
                                                        i
                                                    ]?.cf_vendor_commission
                                                        ?.toLowerCase()
                                                        ?.trim() !== 'yes'
                                                ) {
                                                    updateBillOrderDetails = [
                                                        ...updateBillOrderDetails,
                                                        { ...billOrders[i] },
                                                    ];
                                                }
                                            }
                                        }

                                        console.log(
                                            updateBillOrderDetails,
                                            'bill'
                                        );

                                        const poTotal =
                                            addPurchaseOrdersAmount?.reduce(
                                                (acc, ele) =>
                                                    (acc += ele.total),
                                                0
                                            );
                                        const billTotal =
                                            updateBillOrderDetails?.reduce(
                                                (acc, ele) =>
                                                    (acc += ele.total),
                                                0
                                            );
                                        console.log({ poTotal, billTotal });
                                        const diff = poTotal - billTotal;
                                        const percentage = Math.abs(
                                            (diff / poTotal) * 100
                                        );
                                        console.log(percentage);
                                        console.log({ poTotal, billTotal });
                                        if (
                                            percentage > 2 ||
                                            poTotal <= 0 ||
                                            billTotal <= 0
                                        ) {
                                            setHasAmountError('error');
                                        } else {
                                            formik.setFieldValue(
                                                'amount',
                                                parseFloat(poTotal)
                                            );
                                            setHasAmountError('noError');
                                        }
                                        setGenLoading(false);
                                    }}
                                >
                                    {genLoading ? (
                                        <ClipLoader />
                                    ) : (
                                        'Generate Amount'
                                    )}
                                </PrimaryButton>
                            )}
                        {hasAmountError === 'noError' && (
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        )}
                        {hasAmountError === 'error' && (
                            <PrimaryButton
                                onClick={() => {
                                    const encodeData = {
                                        from: moment(formik.values.from).format(
                                            'YYYY-MM-DD'
                                        ),
                                        to: moment(formik.values.to).format(
                                            'YYYY-MM-DD'
                                        ),
                                        customerName:
                                            formik.values.customerName,
                                        customerId: formik.values.customer,
                                        gst: formik.values.gst,
                                        customerPhone:
                                            formik.values.customerPhone,
                                        vendorMobileNo:
                                            formik.values.vendorMobileNo,
                                        vendorId: formik.values.vendorId,
                                    };
                                    let encodedData = btoa(
                                        JSON.stringify(encodeData)
                                    );
                                    navigate(`/diagnosis/${encodedData}`);
                                }}
                            >
                                Run Diagnosis
                            </PrimaryButton>
                        )}
                    </div>
                </form>
            )}
        </div>
    );
};

export default CreateInvoice;
