import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Layout from './components/infrastructure/Layout';
import PersistLogin from './components/infrastructure/PersistLogin';

import './css/style.scss';
import Dashboard from './pages/Dashboard';
import Login from './pages/Auth/Login';
import PageNotFound from './pages/PageNotFound';
import Logout from './pages/Auth/Logout';

import RouteWithHeaderAndSidebar from './components/infrastructure/RouteWithHeaderAndSidebar';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from './app/reducers/Auth/authSlice';
import CreatePurchaseOrder from './pages/PurchaseOrder/CreatePurchaseOrder';
import Quality from './pages/Master/Quality';
import AddUser from './pages/Auth/AddUser';
import ViewUser from './pages/Auth/ViewUser';
import EditUser from './pages/Auth/EditUser';
import PaymentTerms from './pages/Master/PaymentTerms';
import ViewPurchaseOrder from './pages/PurchaseOrder/ViewPurchaseOrder';
import SplitQuarterData from './pages/SplitQuarterData/SplitQuarterData';
import Notification from './pages/Notification/Notification';
import Diagnosis from './pages/Notification/Diagnosis';
import CreateInvoice from './pages/Invoice/CreateInvoice';
import ViewComplain from './pages/Complain/ViewComplain';
import Delivery from './pages/Delivery/Delivery';
import NotificationSuccess from './pages/Notification/NotificationSuccess';
import Bill from './pages/ViewPdf/Bill';
import PoReport2 from './pages/ViewPdf/PoReport2';
import { AddOrganization } from './pages/Organization/AddOrganization';
import { ViewOrganization } from './pages/Organization/ViewOrganization';
import { EditOrganization } from './pages/Organization/EditOrganization';
import ViewInvoice from './pages/Invoice/ViewInvoice';
import ViewVendors from './pages/Views/ViewVendors';
import ViewCustomer from './pages/Views/ViewCustomer';
import UltraDiagnosis from './pages/UltraDiagnosis/UltraDiagnosis';
import DiagnosisDisplay from './pages/UltraDiagnosis/DiagnosisDisplay';
import DiagnosisProject from './pages/UltraDiagnosis/DiagnosisProject';
import Report from './pages/Report/Report';
import AddAttribute from './pages/Attributes/AddAttribute';
import ViewGroupComplaint from './pages/Complain/ViewGroupComplaint';
import CustomerNotice from './pages/ViewPdf/CustomerNotice';
import ViewNotice from './pages/Notice/ViewNotice';
import ViewQualities from './pages/ViewVendor/ViewQualities';
import CreateVendor from './pages/CreateVendor/CreateVendor';
import FetchAllVendors from './pages/Master/FetchAllVendors';
import QualityType from './pages/QualityType';
import DashboardOld from './pages/DashboardOld';
import VendorByQuality from './pages/VendorByQuality';
import YarnGrade from './pages/Master/YarnGrade';
import CustomerPreference from './pages/CustomerPreference/CustomerPreference';
import FollowUp from './pages/FollowUp/FollowUp';
import PendingOrderList from './pages/PendingOrderList/PendingOrderList';
import DownloadPendingOrderList from './pages/PendingOrderList/DownloadPendingOrderList';
import ReminderSubject from './pages/Master/ReminderSubject';
import { fetchReminderPopUps } from './app/reducers/ReminderPopUp/reminderPopUpSlice';
import moment from 'moment';
import ViewRemindersPage from './pages/viewReminder/ViewRemindersPage';
import TriggerAutomation from './pages/TriggerAutomation/TriggerAutomation';
import Features from './pages/Features/Features';
import EventCalender from './pages/Calender/EventCalender';
import PhoneBook from './pages/Phonebook/PhoneBook';

function App() {
    const location = useLocation();
    const { user } = useSelector(getAuth);
    const dispatch = useDispatch();

    useEffect(() => {
        document.querySelector('html').style.scrollBehavior = 'auto';
        window.scroll({ top: 0 });
        document.querySelector('html').style.scrollBehavior = '';
        console.log(location, 'testing location');
    }, [location.pathname]); // triggered on route change

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(
                fetchReminderPopUps({
                    date: { $lt: moment(new Date()).unix() },
                    status: { $ne: 'cancel' },
                    limit: 1,
                })
            );
        }, 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route element={<PersistLogin />}>
                        <Route exact path="/logout" element={<Logout />} />
                        <Route path="*" element={<PageNotFound />} />
                        <Route path="/" element={<RouteWithHeaderAndSidebar />}>
                            <Route exact path="/" element={<Dashboard />} />
                            <Route
                                exact
                                path="/dashboard-old"
                                element={<DashboardOld />}
                            />
                            <Route
                                exact
                                path="/dashboard/:qualityType"
                                element={<QualityType />}
                            />
                            <Route
                                exact
                                path="/dashboard/:qualityType/:qualityName"
                                element={<VendorByQuality />}
                            />
                            <Route
                                exact
                                path="/addUser"
                                element={<AddUser />}
                            />
                            <Route
                                exact
                                path="/viewUser"
                                element={<ViewUser />}
                            />
                            <Route
                                exact
                                path="/features"
                                element={<Features />}
                            />
                            <Route
                                exact
                                path="/editUser"
                                element={<EditUser />}
                            />
                            <Route
                                exact
                                path="/addOrganization"
                                element={<AddOrganization />}
                            />
                            <Route
                                exact
                                path="/viewOrganization"
                                element={<ViewOrganization />}
                            />
                            <Route
                                exact
                                path="/editOrganization/:id"
                                element={<EditOrganization />}
                            />
                            <Route
                                path="/create-purchase-order"
                                element={<CreatePurchaseOrder />}
                            />
                            <Route
                                path="/view-purchase-order"
                                element={<ViewPurchaseOrder />}
                            />
                            <Route
                                path="/customer-preference"
                                element={<CustomerPreference />}
                            />
                            <Route path="/follow-up" element={<FollowUp />} />
                            <Route
                                path="/trigger-automation"
                                element={<TriggerAutomation />}
                            />
                            <Route
                                path="/pendingOrderList"
                                element={<PendingOrderList />}
                            />
                            <Route
                                path="/downloadPendingOrderList/:id"
                                element={<DownloadPendingOrderList />}
                            />
                            <Route
                                path="/view-previous-order"
                                element={<SplitQuarterData />}
                            />
                            <Route
                                path="/fetch-all-vendors"
                                element={<FetchAllVendors />}
                            />
                            <Route
                                path="/createPaymentTerm"
                                element={<PaymentTerms />}
                            />
                            <Route
                                path="/view-notification"
                                element={<Notification />}
                            />
                            <Route
                                path="/view-sucess-notification"
                                element={<NotificationSuccess />}
                            />
                            <Route
                                path="/diagnosis/:detail"
                                element={<Diagnosis />}
                            />
                            <Route
                                path="/create-invoice"
                                element={<CreateInvoice />}
                            />
                            <Route
                                path="/view-invoice"
                                element={<ViewInvoice />}
                            />
                            <Route
                                path="/view-vendors"
                                element={<ViewVendors />}
                            />
                            <Route
                                path="/view-customer"
                                element={<ViewCustomer />}
                            />
                            <Route path="/podelivery" element={<Delivery />} />
                            <Route
                                path="/view-complain"
                                element={<ViewComplain />}
                            />
                            <Route
                                path="/view-group-complaint"
                                element={<ViewGroupComplaint />}
                            />
                            <Route
                                path="/createQuality"
                                element={<Quality />}
                            />{' '}
                            <Route
                                path="/ultraDiagnosis/:project"
                                element={<UltraDiagnosis />}
                            />
                            <Route
                                path="/diagnosisDisplay"
                                element={<DiagnosisDisplay />}
                            />{' '}
                            <Route
                                path="/ultraDiagnosis"
                                element={<DiagnosisProject />}
                            />{' '}
                            <Route path="/report" element={<Report />} />{' '}
                            <Route
                                path="/AddAttribute"
                                element={<AddAttribute />}
                            />{' '}
                            <Route
                                exact
                                path="/viewNotice"
                                element={<ViewNotice />}
                            />
                            <Route
                                exact
                                path="/viewQualities"
                                element={<ViewQualities />}
                            />
                            <Route
                                exact
                                path="/createVendor"
                                element={<CreateVendor />}
                            />
                            <Route path="/yarnGrade" element={<YarnGrade />} />
                            <Route
                                path="/reminderSubject"
                                element={<ReminderSubject />}
                            />
                            <Route
                                path="/viewRemindersPage"
                                element={<ViewRemindersPage />}
                            />
                            <Route exact path="/eventCalender" element={<EventCalender />} />
                            <Route exact path='/phonebook' element={<PhoneBook />} />
                        </Route>
                        <Route exact path="/billReport" element={<Bill />} />
                        <Route exact path="/poReport" element={<PoReport2 />} />
                        <Route
                            exact
                            path="/customerNotice"
                            element={<CustomerNotice />}
                        />
                        
                    </Route>
                    <Route exact path="/login" element={<Login />} />
                </Route>
            </Routes>
        </>
    );
}

export default App;
