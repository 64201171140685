import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import {
    convertToBill,
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
    updatePurchaseOrderQuantity,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { ClipLoader } from 'react-spinners';
import { delay } from '../../utils/Utils';

const EditPurchaseModal = ({
    viewPurchaseModal,
    setPurchaseModal,
    title,
    data,
}) => {
    const dispatch = useDispatch();
    console.log(data.page);
    const { purchaseOrderLoading } = useSelector(getPurchaseOrder);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: data.id,
            quantity: '',
            meter: 1,
            taka: 1,
            line_items: [],
        },
        onSubmit: async (values, { resetForm }) => {
            let data = {
                id: values.id,
                line_items: [
                    { ...values.line_items[0], quantity: values.quantity },
                ],
            };
            await dispatch(updatePurchaseOrderQuantity(data));
            await dispatch(convertToBill({ id: values.id }));
            dispatch(fetchPurchaseOrders({ page: data.page, status: 'open' }));
            setPurchaseModal(false);
        },
    });

    useEffect(async () => {
        if (data.id) {
            console.log('insert', data.id);
            const action = await dispatch(fetchPurchaseOrder({ id: data.id }));
            if (action.payload.purchaseorder) {
                formik.setFieldValue(
                    'quantity',
                    action.payload.purchaseorder.total_quantity
                );
                formik.setFieldValue(
                    'line_items',
                    action.payload.purchaseorder.line_items
                );
            }
        }
    }, [data.id]);

    return (
        <ModalBasic
            title={title}
            modalOpen={viewPurchaseModal}
            setModalOpen={setPurchaseModal}
        >
            {purchaseOrderLoading ? (
                <ClipLoader />
            ) : (
                <form
                    className="flex flex-col gap-4 p-4"
                    onSubmit={formik.handleSubmit}
                >
                    <FormikInputGroup
                        label="PO Number"
                        formik={formik}
                        type="number"
                        name="id"
                        readOnly
                    />
                    <div className="flex gap-5">
                        <FormikInputGroup
                            label="Meter"
                            formik={formik}
                            type="number"
                            name="meter"
                        />
                        <FormikInputGroup
                            label="Taka"
                            formik={formik}
                            type="number"
                            name="taka"
                        />
                        <p className="self-center">
                            Quantity:-{' '}
                            <span
                                className="font-bold cursor-pointer"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    formik.setFieldValue(
                                        'quantity',
                                        formik.values.taka === 0
                                            ? 0
                                            : formik.values.meter /
                                                  formik.values.taka
                                    );
                                }}
                            >
                                {formik.values.taka === 0
                                    ? 0
                                    : formik.values.meter / formik.values.taka}
                            </span>
                        </p>
                    </div>
                    <FormikInputGroup
                        label="Quantity"
                        autoFocus
                        formik={formik}
                        type="number"
                        name="quantity"
                    />
                    <div>
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default EditPurchaseModal;
