import React, { useState } from 'react';
import UserMenu from '../components/infrastructure/UserMenu';
import moment from 'moment';
import _ from 'lodash';
import { useFormik } from 'formik';
import FormikAsyncSelect from '../components/formik/FormikAsyncSelect';
import { customIDs } from '../utils/customIds';
import QueryString from 'qs';
import { authAxiosInstance } from '../utils/axiosConfig';
import FormikSelectGroup from '../components/formik/FormikSelectGroup';
import { generateOptions } from '../utils/Utils';
import { Bell, Clock } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import {
    getReminderPopUp,
    openReminderPopUp,
} from '../app/reducers/ReminderPopUp/reminderPopUpSlice';
import { Link, useNavigate } from 'react-router-dom';
import PhoneIcon from '../images/phoneCall.png';
import CalenderIcon from '../images/calenderIcon.png';
// import SearchModal from '../components/ModalSearch';
// import Notifications from '../components/DropdownNotifications';
// import Help from '../components/DropdownHelp';
// import UserMenu from '../components/DropdownProfile';

function Header({ sidebarOpen, setSidebarOpen }) {
    const formik = useFormik({
        initialValues: {
            group: '',
            chalan: '',
        },
    });
    const { reminderPopUp } = useSelector(getReminderPopUp);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [dateDifference, setDateDifference] = useState(false);
    let [search, setSearch] = useState();
    let [loading, setLoading] = useState(false);
    let [chalanOption, setChalanOptions] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <header className="sticky top-0 bg-white border-b border-slate-200 z-30">
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16 -mb-px">
                    {/* Header: Left side */}
                    <div className="flex">
                        {/* Hamburger button */}
                        <button
                            className="text-slate-500 hover:text-slate-600 lg:hidden"
                            aria-controls="sidebar"
                            aria-expanded={sidebarOpen}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg
                                className="w-6 h-6 fill-current"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect x="4" y="5" width="16" height="2" />
                                <rect x="4" y="11" width="16" height="2" />
                                <rect x="4" y="17" width="16" height="2" />
                            </svg>
                        </button>
                    </div>
                    <form
                        onSubmit={formik.handleSubmit}
                        className="hidden md:grid md:grid-cols-2 gap-2 w-1/2"
                    >
                        <FormikAsyncSelect
                            formik={formik}
                            name={'group'}
                            getOptions={async (value) => {
                                setSearch(value);

                                //Challan Name Search
                                let payload = {
                                    [`custom_field_${customIDs.customer_chalan_name}_contains`]:
                                        value,
                                };
                                let string = QueryString.stringify(payload);
                                const actionDetails =
                                    await authAxiosInstance.get(
                                        `purchase-orders/chalan/contacts?${string}`
                                    );
                                let chalanDetails =
                                    actionDetails.data?.data?.contacts;

                                // Chalan Name 1
                                let payloadDetails = {
                                    [`custom_field_${customIDs.customer_chalan_name1}_contains`]:
                                        value,
                                };
                                let string2 =
                                    QueryString.stringify(payloadDetails);
                                const action = await authAxiosInstance.get(
                                    `purchase-orders/chalan/contacts?${string2}`
                                );
                                let chalanName1Details =
                                    action.data?.data?.contacts;

                                //Merge Details
                                let mergeDetails = [
                                    ...chalanDetails,
                                    ...chalanName1Details,
                                ].map((d) => d.company_name);
                                let finalData = [...new Set(mergeDetails)];

                                const serverResp = finalData.map((ele) => ({
                                    label: `${ele}`,
                                    value: `${ele}`,
                                }));

                                return [...serverResp];
                            }}
                            onChange={async (option) => {
                                setLoading(true);
                                //Find details of grop name
                                let payloadDetails = {
                                    ['company_name_contains']: option.value,
                                    contact_type: 'vendor',
                                };
                                let string2 =
                                    QueryString.stringify(payloadDetails);
                                const action = await authAxiosInstance.get(
                                    `purchase-orders/chalan/contacts?${string2}`
                                );
                                let companyDetails =
                                    action.data?.data?.contacts[0];

                                //Set Challan Options
                                let chalanName1 =
                                    companyDetails?.cf_chalan_name_1
                                        ? companyDetails?.cf_chalan_name_1?.split(
                                              ','
                                          )
                                        : [];
                                let chalanDetails = [
                                    ...new Set([
                                        ...companyDetails?.cf_chalan_name?.split(
                                            ','
                                        ),
                                        ...chalanName1,
                                    ]),
                                ];
                                // Fetch Po Detials
                                // let poFetchDetails = {
                                //   [`custom_field_${customIDs.purchase_custom_buyerName}_contains`]:
                                //     formik.values.customer,
                                //   "vendor_id": companyDetails?.contact_id,
                                //   date_start: moment(formik.values.start).format('YYYY-MM-DD'),
                                //   date_end: moment(formik.values.end).format('YYYY-MM-DD'),
                                // };
                                // let string3 = QueryString.stringify(poFetchDetails);
                                // const action3 = await authAxiosInstance.get(
                                //   `purchase-orders?${string3}`
                                // );
                                // let poDetails = action3.data?.data?.purchaseorders;
                                // console.log(poDetails, "poDetailhu")
                                // if (poDetails?.length <= 0) {
                                //   return alert('Purchase Orders Not Found')
                                // }
                                setChalanOptions(
                                    chalanDetails
                                        .sort(
                                            (a, b) =>
                                                a.toLowerCase() -
                                                b.toLowerCase()
                                        )
                                        .map((d) => ({ label: d, value: d }))
                                );

                                let findData = chalanDetails.find((d) =>
                                    d
                                        .toLowerCase()
                                        .includes(search.toLowerCase())
                                );
                                if (findData) {
                                    formik.setFieldValue(`chalan`, findData);
                                }
                                formik.setFieldValue(`group`, option.value);
                                setLoading(false);
                            }}
                        />
                        <FormikSelectGroup
                            formik={formik}
                            name={`chalan`}
                            options={generateOptions({
                                array: chalanOption ? chalanOption : [],
                                labelField: 'label',
                                valueField: 'value',
                            })}
                        />
                    </form>

                    {/* Header: Right side */}
                    <div className="flex items-center space-x-3">
                        <span 
                            className="cursor-pointer"
                            onClick={(e)=>{
                                e.stopPropagation();
                                navigate(`/eventCalender`)
                            }}
                        >
                            <img src={CalenderIcon} className="w-20px h-20px" />
                        </span>
                        <span 
                            className="cursor-pointer"
                            onClick={(e)=>{
                                e.stopPropagation();
                                navigate(`/phonebook`)
                            }}
                        >
                            <img src={PhoneIcon} className="w-20px h-20px" />
                        </span>
                        <input
                            placeholder="Enter complaint date"
                            type="text"
                            onChange={(e) => {
                                console.log(e.target.value);
                                const dateStr = e.target.value;
                                const day = parseInt(
                                    dateStr.substring(0, 2),
                                    10
                                );
                                const month = parseInt(
                                    dateStr.substring(2),
                                    10
                                );
                                console.log('month', month);
                                console.log('day', day);
                                const currentDate = moment();
                                const targetDate = moment().set({
                                    month: month - 1,
                                    date: day,
                                });
                                console.log('targetDate', targetDate);
                                if (currentDate.isBefore(targetDate)) {
                                    const previousYear = moment().subtract(
                                        1,
                                        'year'
                                    );
                                    const previousYearTargetDate = moment(
                                        previousYear
                                    ).set({
                                        month: month - 1,
                                        date: day,
                                    });
                                    const daysDifference = currentDate.diff(
                                        previousYearTargetDate,
                                        'days'
                                    );
                                    setDateDifference(daysDifference);
                                    console.log(
                                        `Number of days between ${dateStr} and the current date: ${daysDifference} days (from the previous year)`
                                    );
                                } else {
                                    const daysDifference = targetDate.diff(
                                        currentDate,
                                        'days'
                                    );
                                    setDateDifference(daysDifference);

                                    console.log(
                                        `Number of days between ${dateStr} and the current date: ${daysDifference} days`
                                    );
                                }
                            }}
                        />
                        {_.isFinite(dateDifference) && (
                            <h1 className="w-20 ">{`${dateDifference} days`}</h1>
                        )}
                        {/*  Divider */}
                        <Link to={'/viewRemindersPage'} className="relative">
                            <Bell size={30} />
                            <div className=" absolute top-0 right-0 translate-x-2 -translate-y-2 bg-green-500 size-5 rounded-full text-xs flex items-center justify-center text-white">
                                {reminderPopUp?.totalDocs
                                    ? reminderPopUp.totalDocs
                                    : 0}
                            </div>
                        </Link>
                        <button
                            onClick={() =>
                                dispatch(
                                    openReminderPopUp({
                                        isOpen: true,
                                        data: {},
                                    })
                                )
                            }
                        >
                            <Clock />
                        </button>
                        <UserMenu align="right" />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
