import React, { useState } from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

const ToggleButton = ({
    toggled,  // Controlled prop
    onChange,
    onClick,
    ...props
}) => {
   
    const handleToggleChange = () => {
        const newState = !toggled;
        if (onChange) {
            onChange(newState);  
        }
    };

    return (
        <label>
            <Toggle
                checked={toggled}  
                icons={false}
                onChange={handleToggleChange}
                onClick={onClick}
                {...props}
            />
        </label>
    );
};

export default ToggleButton;
