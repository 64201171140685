import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import {
    fetchCustomers,
    getCustomer,
} from '../..//app/reducers/Customer/customerSlice';
import { useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
// import EditCustomer from "./EditCustomer";
import { customIDs } from '../../utils/customIds';
import EditCustomerModal from './EditCustomerModal';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { useFormik } from 'formik';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { generateOptions } from '../../utils/Utils';
import {
    fetchQualities,
    getQuality,
} from '../../app/reducers/Quality/qualitySlice';
import EditCustomer from '../EditCustomer';
import { useNavigate } from 'react-router-dom';
import { Clock, Edit2 } from 'react-feather';
import moment from 'moment';
import { openReminderPopUp } from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';
import { ClockLoader } from 'react-spinners';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import { useCallback } from 'react';
import { authAxiosInstance } from '../../utils/axiosConfig';

const ViewCustomer = () => {
    const [loadingReminder, setLoadingReminder] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [index, setIndex] = useState(0);
    const [editData, setData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [isCustomerModalOpen, setCustomerModalOpen] = useState(false);
    const [section, setSection] = useState(['all']);
    const [page, setPage] = useState(1);

    const dispatch = useDispatch();

    const { customer_calling } = customIDs;

    const debouncedSearch = useCallback(
        _.debounce(async (search) => {
            dispatch(
                fetchCustomers({
                    contact_type: 'customer',
                    search: search,
                    [`custom_field_${customIDs.customer_title}_contains`]:
                        formik.values.quality,
                })
            );
        }, 300),
        []
    );

    useEffect(() => {
        dispatch(
            fetchCustomers({
                contact_type: 'customer',
            })
        );
        dispatch(fetchQualities({ limit: 300, sort: { priority: -1 } }));
    }, []);
    const { customers, loading } = useSelector(getCustomer);

    const cols = [
        // {
        //     Header: "Select",
        //     Cell: ({ row }) => {
        //         return (
        //             <input
        //                 type="checkbox"
        //                 onChange={(e) => {
        //                     if (e.target.checked) {
        //                         setSelectedRows([...selectedRows, row.original]);
        //                     } else {
        //                         let filtered = selectedRows.filter(
        //                             (ele) => ele.contact_id !== row.original.contact_id
        //                         );
        //                         setSelectedRows(filtered);
        //                     }
        //                 }}
        //                 checked={selectedRows.find(
        //                     (ele) => ele?.contact_id === row?.original?.contact_id
        //                 )}
        //             />
        //         );
        //     },
        // },
        {
            Header: 'SRNO',
            Cell: ({ row, data }) => {
                return row.index + 1;
            },
        },
        {
            Header: 'Company Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex gap-2 cursor-pointer">
                        <div>{row?.original?.company_name}</div>
                        {row?.original?.priority?.toUpperCase() === 'HIGH' && (
                            <CheckCircle color="green" />
                        )}
                    </div>
                );
            },
            accessor: 'company_name',
        },
        {
            Header: 'First Name',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer ">
                        {row?.original?.first_name && (
                            <span>{row?.original?.first_name}</span>
                        )}
                    </div>
                );
            },
            accessor: 'first_name',
        },
        {
            Header: 'Mobile',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.mobile && (
                            <span
                                onClick={(e) => {
                                    e.stopPropagation();
                                    // setVendorModalOpen(true);
                                    // console.log(row.original);
                                    // setEditVendorData(row.original);
                                    setSection(['phone']);
                                }}
                            >
                                {row?.original?.mobile}
                            </span>
                        )}
                    </div>
                );
            },
            accessor: 'mobile',
        },
        {
            Header: 'Work',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.phone && (
                            <span>{row?.original?.phone}</span>
                        )}
                    </div>
                );
            },
            accessor: 'phone',
        },
        {
            Header: 'Q1',
            Cell: ({ row, data }) => {
                return row.original?.cf_q1 ? row.original?.cf_q1 : '';
            },
        },
        {
            Header: 'Q2',
            Cell: ({ row, data }) => {
                return row.original?.cf_credit_score_fy_q2
                    ? row.original?.cf_credit_score_fy_q2
                    : '';
            },
        },
        {
            Header: 'Q3',
            Cell: ({ row, data }) => {
                return row.original?.cf_credit_score_fy_q3
                    ? row.original?.cf_credit_score_fy_q3
                    : '';
            },
        },
        {
            Header: 'Q4',
            Cell: ({ row, data }) => {
                return row.original?.cf_credit_score_fy_q4
                    ? row.original?.cf_credit_score_fy_q4
                    : '';
            },
        },
        {
            Header: 'Update date',
            Cell: ({ row }) => {
                // console.log("row", row, row?.original?.updatedAt)
                return (
                    <div className="flex flex-col gap-1 cursor-pointer">
                        {row?.original?.cf_last_update_date && (
                            <span>
                                {moment(
                                    row?.original?.cf_last_update_date
                                ).format('YYYY-MM-DD')}
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            Header: 'Action',
            Cell: ({ row, data }) => {
                return (
                    <div className="flex gap-4">
                        <Edit2
                            onClick={async (e) => {
                                e.stopPropagation();
                                setCustomerModalOpen(true);
                                setSection(['all']);

                                setData(row.original);
                            }}
                        />
                        {loadingReminder == row.original._id ? (
                            <ClockLoader size={25} speedMultiplier={2} />
                        ) : (
                            <button
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    console.log('row', row.original);
                                    setLoadingReminder(row.original._id);
                                    let popUpData = {};
                                    popUpData['customerMobileNo'] =
                                        row.original?.mobile;
                                    popUpData['cf_buyer_name'] =
                                        row.original?.contact_name;
                                    dispatch(
                                        openReminderPopUp({
                                            isOpen: true,
                                            data: {
                                                ...popUpData,
                                                type: 'customer',
                                                reminderType: 'reminder',
                                                path: 'contacts',
                                                path_id:
                                                    row.original?.contact_id,
                                            },
                                        })
                                    );
                                    setLoadingReminder('');
                                }}
                            >
                                <Clock
                                    size={25}
                                    color={
                                        row.original['cf_reminder'] == 'YES'
                                            ? '#ffff1a'
                                            : 'black'
                                    }
                                />
                            </button>
                        )}
                        <PrimaryButton
                            className={'whitespace-nowrap'}
                            onClick={(e) => {
                                e.stopPropagation();
                                setData(row.original);
                                setModalOpen(true);
                            }}
                        >
                            Credit Report
                        </PrimaryButton>
                    </div>
                );
            },
        },
    ];
    const { quality, loading: qualityLoading } = useSelector(getQuality);
    const qualityData = useMemo(() => (quality.docs ? quality.docs : []));
    const formik = useFormik({
        initialValues: {
            quality: '',
        },
        onSubmit: async (values) => {
            await dispatch(
                fetchCustomers({
                    contact_type: 'customer',
                    [`custom_field_${customIDs.customer_title}_contains`]:
                        values.quality,
                })
            );
        },
    });

    // console.log(custom)
    const customerData = useMemo(
        () => (customers?.contacts ? customers.contacts : []),
        [customers]
    );

    const customerPageContext = useMemo(
        () => (customers?.page_context ? customers?.page_context : []),
        [customers]
    );
    console.log('page context', customerPageContext)
    const navigate = useNavigate();
    return (
        <>
            <EditCustomerModal
                title={'Check Report'}
                setCustomerModal={setModalOpen}
                viewCustomerModal={modalOpen}
                data={editData}
            />
            <EditCustomer
                title={'Edit Customer'}
                setIsOpen={setCustomerModalOpen}
                isOpen={isCustomerModalOpen}
                data={editData}
                section={section}
            />
            <form className="p-4" onSubmit={formik.handleSubmit}>
                <div className="grid grid-cols-2 my-4">
                    {/* <FormikInputGroup
            formik={formik}
            name={"quality"}
            label="Quality" /> */}
                    <FormikSelectGroup
                        options={generateOptions({
                            array: quality.docs ? quality.docs : [],
                            labelField: 'name',
                            valueField: 'tag',
                        })}
                        formik={formik}
                        name={'quality'}
                        label="Quality"
                    />
                </div>
                <PrimaryButton type="submit">submit</PrimaryButton>
            </form>
            {selectedRows && selectedRows.length > 0 && (
                <div className="mt-3 mb-2 ms-5">
                    <PrimaryButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setModalOpen(true);
                        }}
                    >
                        Generate Credit Report
                    </PrimaryButton>
                </div>
            )}
            <div className="p-4" style={{ overflow: 'hidden' }}>
                <TableWithHeadingAndGlobalSearch
                    heading={
                        <div className="flex items-center gap-3">
                            <span>Customer</span>

                            <PrimaryButton
                                onClick={() => {
                                    navigate('/view-vendors');
                                }}
                            >
                                Vendors
                            </PrimaryButton>
                            <PrimaryButton
                                onClick={async(e)=>{
                                    e.stopPropagation();
                                    const resp = await authAxiosInstance.get(
                                        `/purchase-orders/order/qualityTag`
                                    )
                                    console.log('resp',resp)
                                }}
                            >
                                Customer Quality Tag
                            </PrimaryButton>
                        </div>
                    }
                    data={customerData}
                    loading={loading}
                    columns={cols}
                    searchFunction={debouncedSearch}
                />
                {!loading && (
                    <div className="mt-4 flex gap-4 justify-end">
                        {page > 1 && (
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPage(page - 1);
                                }}
                            >
                                Previous
                            </PrimaryButton>
                        )}
                        {customerPageContext.has_more_page && (
                            <PrimaryButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPage(page + 1);
                                }}
                            >
                                Next
                            </PrimaryButton>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ViewCustomer;
