import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import TableWithHeadingAndGlobalSearch from '../../components/Table/TableWithHeadingAndGlobalSearch';
import _ from 'lodash';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Edit2, Trash, Globe } from 'react-feather';
import { useState } from 'react';
import PaginationClassic from '../../components/pagination/PaginationClassic';
import {
    convertToBill,
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
    reset,
    updatePurchaseOrderQuantity,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
// import EditPurchaseModal from "./EditPurchaseModal";
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import { useFormik } from 'formik';
import EditMenu from '../../components/DropdownEditMenu/DropdownEditMenu';
import * as Yup from 'yup';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { fetchContacts } from '../../app/reducers/Contacts/contactsSlice';
import EditComplain from '../Complain/EditComplain';
import ResolveComplain from '../Complain/ResolveComplain';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import TertiaryButton from '../../components/infrastructure/Buttons/TertiaryButton';
import PrimaryBadge from '../../components/infrastructure/Badges/PrimaryBadge';
import ReOpenComplaint from '../Complain/ReOpenComplaint';
import { customIDs } from '../../utils/customIds';
import SecondaryBadge from '../../components/infrastructure/Badges/SecondaryBadge';

const Delivery = () => {
    const [page, setPage] = useState(1);
    const [viewComplainModal, setComplainModal] = useState(false);
    const [viewResolveModal, setResolveModal] = useState(false);
    const [reOpenComplaintModal, setReOpenComplaintModal] = useState(false);
    const [editData, setEditData] = useState({});
    const dispatch = useDispatch();

    const { purchaseOrder, loading } = useSelector(getPurchaseOrder); // created on 16-11-22

    const purchaseOrdersData = useMemo(
        () =>
            purchaseOrder?.purchaseorders ? purchaseOrder.purchaseorders : [],
        [purchaseOrder]
    );

    useEffect(() => {
        dispatch(reset());
    }, []);

    const purchaseOrderPageContext = useMemo(
        () => (purchaseOrder?.page_context ? purchaseOrder.page_context : []),
        [purchaseOrder]
    );

    const formik = useFormik({
        initialValues: {
            vendor: '',
            customer: '',
        },

        onSubmit: async (values) => {
            const filters = {
                page,
                sort_column: 'date',
            };
            if (values.vendor) {
                filters['vendor_name_contains'] = values.vendor;
            }
            if (values.customer) {
                filters['cf_buyer_name_contains'] = values.customer;
            }

            const action = await dispatch(fetchPurchaseOrders(filters));
        },
    });
    const generateComplain = (e, data) => {
        e.stopPropagation();
        setEditData({ id: data.purchaseorder_id, page: page });
        setComplainModal(true);
    };
    const reOpenComplaintAction = (e, data) => {
        e.stopPropagation();
        setEditData({ id: data.purchaseorder_id, page: page });
        setReOpenComplaintModal(true);
    };

    const resolveComplain = (e, data) => {
        e.stopPropagation();
        setEditData({ id: data.purchaseorder_id, page: page });
        setResolveModal(true);
    };

    const complainClose = async (e, data) => {
        e.stopPropagation();
        console.log(data);
        let updateData = {
            id: data.purchaseorder_id,
            custom_fields: [
                {
                    value: '',
                    customfield_id: customIDs.purchase_payment_complaint,
                },
                {
                    value: '',
                    customfield_id: customIDs.purchase_custom_has_complaint,
                },
                {
                    value: moment().format('DD-MM-YYYY'),
                    customfield_id:
                        customIDs.purchase_custom_complaint_cloze_date,
                },
            ],
        };
        await dispatch(updatePurchaseOrderQuantity(updateData));
        formik.submitForm();
    };

    const cols = [
        {
            Header: 'Action',
            Cell: ({ row, data }) => {
                let tag = 'noComplaint';
                if (row.original.cf_has_complaint === 'yes') {
                    tag = 'complaintRaised';
                }
                if (row.original.cf_tag === 'PAYMENT COMPLAINT') {
                    tag = 'complaintAccepted';
                }
                if (row.original.cf_tag_2 === 'COMPLAINT SOLVE') {
                    tag = 'complaintSolved';
                }

                if (
                    row.original.cf_tag_2 === 'COMPLAINT SOLVE' &&
                    row.original.cf_tag !== 'PAYMENT COMPLAINT'
                ) {
                    tag = 'complaintClosed';
                }
                return (
                    <div
                        className="flex items-center gap-2"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {tag !== 'complaintClosed' ? (
                            <div className="flex gap-2 items-center">
                                <PrimaryButton
                                    onClick={(e) =>
                                        generateComplain(e, row.original)
                                    }
                                >
                                    G
                                </PrimaryButton>
                                <SecondaryButton
                                    onClick={(e) =>
                                        resolveComplain(e, row.original)
                                    }
                                >
                                    R
                                </SecondaryButton>
                                <TertiaryButton
                                    onClick={(e) =>
                                        complainClose(e, row.original)
                                    }
                                >
                                    C
                                </TertiaryButton>
                                <EditMenu
                                    align="left"
                                    className="relative inline-flex"
                                >
                                    <li>
                                        <span
                                            className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Complaint Followup
                                        </span>
                                    </li>
                                    <hr></hr>
                                    <li>
                                        <span
                                            className="font-medium text-sm text-slate-600 hover:text-slate-800 flex py-1 px-3"
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Complain Remainder
                                        </span>
                                    </li>
                                </EditMenu>
                            </div>
                        ) : (
                            <div className="flex justify-center items-center">
                                <PrimaryButton
                                    className={'text-xs'}
                                    onClick={(e) =>
                                        reOpenComplaintAction(e, row.original)
                                    }
                                >
                                    Reopen Complaint
                                </PrimaryButton>
                            </div>
                        )}
                    </div>
                );
            },
        },

        {
            Header: 'PURCHASE ORDER#',

            Cell: ({ row }) => {
                const today = moment();
                let tag = 'noComplaint';
                if (row.original.cf_has_complaint === 'yes') {
                    tag = 'complaintRaised';
                }
                if (row.original.cf_tag === 'PAYMENT COMPLAINT') {
                    tag = 'complaintAccepted';
                }
                if (row.original.cf_tag_2 === 'COMPLAINT SOLVE') {
                    tag = 'complaintSolved';
                }

                if (
                    row.original.cf_tag_2 === 'COMPLAINT SOLVE' &&
                    row.original.cf_tag !== 'PAYMENT COMPLAINT'
                ) {
                    tag = 'complaintClosed';
                }
                console.log(tag, 'tag');
                if (
                    today.isAfter(
                        moment(row.original.date).add(
                            parseInt(row.original.cf_copy_payment_terms),
                            'days'
                        )
                    )
                ) {
                    return (
                        <div className="flex flex-col justify-center items-start ">
                            <span className=" text-red-700">
                                {row.original.purchaseorder_number}
                            </span>
                            <span className="text-[0.5rem]">
                                {row?.original?.cf_last_c_uplod_dt}
                            </span>
                            {
                                {
                                    complaintRaised: (
                                        <SecondaryBadge>
                                            UnApproved Complaint
                                        </SecondaryBadge>
                                    ),
                                    complaintAccepted: (
                                        <PrimaryBadge>
                                            Complaint Accepted
                                        </PrimaryBadge>
                                    ),
                                    complaintSolved: (
                                        <PrimaryBadge>
                                            Complaint Solved
                                        </PrimaryBadge>
                                    ),
                                    complaintClosed: (
                                        <PrimaryBadge>
                                            Complaint Closed
                                        </PrimaryBadge>
                                    ),
                                }[tag]
                            }
                        </div>
                    );
                } else {
                    return (
                        <div className="flex flex-col justify-center items-start">
                            <span className="text-green-700">
                                {row.original.purchaseorder_number}
                            </span>
                            {
                                {
                                    complaintRaised: (
                                        <SecondaryBadge>
                                            UnApproved Complaint
                                        </SecondaryBadge>
                                    ),
                                    complaintAccepted: (
                                        <PrimaryBadge>
                                            Complaint Accepted
                                        </PrimaryBadge>
                                    ),
                                    complaintSolved: (
                                        <PrimaryBadge>
                                            Complaint Solved
                                        </PrimaryBadge>
                                    ),
                                    complaintClosed: (
                                        <PrimaryBadge>
                                            Complaint Closed
                                        </PrimaryBadge>
                                    ),
                                }[tag]
                            }
                        </div>
                    );
                }
            },
            style: {
                width: '15%',
            },
        },
        {
            Header: 'QTY TAKA',
            accessor: 'cf_qty_taka',
            style: {
                width: '8%',
            },
        },
        {
            Header: 'QUALITY NAME',
            accessor: 'cf_quality_name',
        },
        {
            Header: 'VENDOR NAME',
            accessor: 'vendor_name',
            Cell: ({ row }) => {
                return (
                    <div className="grid grid-cols-1">
                        <span>{row.original.vendor_name}</span>
                        {row.original?.cf_complaint_note && (
                            <PrimaryBadge>
                                {row.original.cf_complaint_note}
                            </PrimaryBadge>
                        )}
                    </div>
                );
            },
            style: {
                width: '20%',
            },
        },
        {
            Header: 'BUYER NAME',
            accessor: 'cf_buyer_name',
            Cell: ({ row }) => {
                return (
                    <div className="grid grid-cols-1">
                        <span>{row.original.cf_buyer_name}</span>
                        {row.original?.cf_solve_note && (
                            <PrimaryBadge>
                                {row.original.cf_solve_note}
                            </PrimaryBadge>
                        )}
                    </div>
                );
            },
            style: {
                width: '20%',
            },
        },
        {
            Header: 'DATE',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col">
                        <span>
                            Issue Date{' '}
                            {moment(row.original.date).format('DD/MM/YYYY')}
                        </span>
                        <span>
                            Delivery Date{' '}
                            {moment(row.original.delivery_date).format(
                                'DD/MM/YYYY'
                            )}
                        </span>
                        {row?.original?.cf_copy_payment_terms_2 && (
                            <span>
                                Payment Terms:{' '}
                                {row.original.cf_copy_payment_terms_2}
                            </span>
                        )}
                    </div>
                );
            },
            style: {
                width: '20%',
            },
        },
        {
            Header: 'RATE',
            accessor: 'cf_rate',
        },
    ];

    const handleNext = (e) => {
        e.preventDefault();
        setPage(page + 1);
        setTimeout(() => {
            formik.submitForm();
        }, 20);
    };

    const debouncedSearch = useCallback(
        _.debounce((search) => {
            let data = {};
            data['vendor_name_contains'] = search;
            dispatch(
                fetchPurchaseOrders({
                    ...data,
                    page: 1,
                    status: 'open',
                })
            );
        }, 300),
        [JSON.stringify(formik.values)]
    );

    return (
        <div className="p-4">
            <EditComplain
                title={'Edit Complain'}
                setComplainModal={setComplainModal}
                viewComplainModal={viewComplainModal}
                data={editData}
                formik={formik}
            />
            <ResolveComplain
                title={'Resolve Complain'}
                setResolveModal={setResolveModal}
                viewResolveModal={viewResolveModal}
                formik={formik}
                data={editData}
            />
            <ReOpenComplaint
                reOpenComplaintModal={reOpenComplaintModal}
                setReOpenComplaintModal={setReOpenComplaintModal}
                title={'Re Open Complaint'}
                formik={formik}
                data={editData}
            />
            <div className="mb-3">
                <form
                    onSubmit={formik.handleSubmit}
                    className="grid grid-cols-2 gap-4"
                >
                    <FormikAsyncSelect
                        name="vendor"
                        label="Vendor"
                        formik={formik}
                        getOptions={async (value) => {
                            const action = await dispatch(
                                fetchContacts({
                                    search: value,
                                    contact_type: 'vendor',
                                })
                            );
                            const serverResp = action.payload.contacts.map(
                                (ele) => ({
                                    label: `${ele.contact_name}`,
                                    value: `${ele.contact_name}`,
                                })
                            );
                            return serverResp;
                        }}
                        onChange={(option) => {
                            formik.setFieldValue('vendor', option.value);
                        }}
                    />
                    <FormikAsyncSelect
                        name="customer"
                        label="Customer"
                        formik={formik}
                        getOptions={async (value) => {
                            const action = await dispatch(
                                fetchContacts({
                                    search: value,
                                    contact_type: 'customer',
                                })
                            );
                            const serverResp = action.payload.contacts.map(
                                (ele) => ({
                                    label: `${ele.contact_name}`,
                                    value: `${ele.contact_name}`,
                                })
                            );
                            return serverResp;
                        }}
                    />
                    <div className="col-span-2">
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            </div>
            {loading ? (
                <ClipLoader />
            ) : (
                <TableWithHeadingAndSearch
                    heading={'Purchase Orders'}
                    data={purchaseOrdersData}
                    columns={cols}
                />
            )}
            {!loading && (
                <div className="mt-4 flex gap-3 justify-end">
                    {page > 1 && (
                        <PrimaryButton
                            onClick={(e) => {
                                setPage(page - 1);
                                setTimeout(() => {
                                    formik.submitForm();
                                }, 200);
                            }}
                        >
                            Previous
                        </PrimaryButton>
                    )}
                    {purchaseOrderPageContext.has_more_page && (
                        <PrimaryButton onClick={(e) => handleNext(e)}>
                            Next
                        </PrimaryButton>
                    )}
                </div>
            )}
        </div>
    );
};

export default Delivery;
