import React, { useEffect, useState } from 'react';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useFormik } from 'formik';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputDateGroup from '../../components/formik/FormikInputDateGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import { PO_TYPE } from '../../utils/dropdownOptions';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchPurchaseOrder,
    getPurchaseOrder,
    updatePurchaseOrderQuantity,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import { ClipLoader } from 'react-spinners';
import { customIDs } from '../../utils/customIds';
const moment = require('moment-timezone');

const SplitScheduleModal = ({
    showSplitScheduleModal,
    setShowSplitScheduleModal,
    requestData,
}) => {
    const { id } = requestData;
    const [poOrder, setPoOrder] = useState(null);
    const { purchaseOrderLoading } = useSelector(getPurchaseOrder);

    const dispatch = useDispatch();

    // const computeInitialValue = (data) => {
    //     if (data) {
    //         const formattedScheduleDate = moment(
    //             data?.custom_field_hash?.cf_split_scheduled,
    //             'DD/MM/YYYY'
    //         )
    //             .tz('Asia/Kolkata')
    //             .toDate();

    //         const formattedNewPoDate = moment(
    //             data?.custom_field_hash?.cf_split_new_po_date,
    //             'DD/MM/YYYY'
    //         )
    //             .tz('Asia/Kolkata')
    //             .toDate();
    //         const formattedCurrentPoDate = moment(
    //             data?.custom_field_hash?.cf_splite_sch_current_date,
    //             'DD/MM/YYYY'
    //         )
    //             .tz('Asia/Kolkata')
    //             .toDate();
    //         return {
    //             scheduleDate: formattedScheduleDate,
    //             type: data?.custom_field_hash?.cf_splite_type,
    //             currentPoNo: data?.custom_field_hash?.cf_split_current_po,
    //             currentPoDate: formattedCurrentPoDate,
    //             currentPoQtyTaka:
    //                 data?.custom_field_hash?.cf_splite_current_po_taka,
    //             currentPoQty:
    //                 data?.custom_field_hash?.cf_splite_current_po_quantity,
    //             newPoDate: formattedNewPoDate,
    //             newPoQtyTaka: data?.custom_field_hash?.cf_splite_new_po_taka,
    //             newPoQty: data?.custom_field_hash?.cf_splite_new_po_quantity,
    //         };
    //     } else {
    //         return {
    //             scheduleDate: null,
    //             type: '',
    //             currentPoNo: '',
    //             currentPoDate: null,
    //             currentPoQtyTaka: '',
    //             currentPoQty: '',
    //             newPoDate: null,
    //             newPoQtyTaka: '',
    //             newPoQty: '',
    //         };
    //     }
    // };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            splitSchedule: false,
            scheduleDate: null,
            type: '',
            currentPoNo: '',
            fullPoDate: null,
            currentPoQtyTaka: '',
            currentPoQty: '',
            newPoDate: null,
            newPoQtyTaka: '',
            newPoQty: '',
        },
        onSubmit: (values) => {
            console.log('values', values);
            const currentTodayDateDate = moment(values?.scheduleDate)
                .tz('Asia/Kolkata')
                .format('DD/MM/YYYY');
            const newPODate = moment(values?.newPoDate)
                .tz('Asia/Kolkata')
                .format('DD/MM/YYYY');
            const fullPODate = moment(values?.fullPoDate)
                .tz('Asia/Kolkata')
                .format('DD/MM/YYYY');

            let new_custom_fields;

            if (values?.type === 'New') {
                new_custom_fields = [
                    {
                        customfield_id: customIDs.split_full_po_date,
                        value: values?.fullPoDate ? fullPODate : '',
                    },
                    {
                        customfield_id: customIDs.split_new_po_date,
                        value: values?.newPoDate ? newPODate : '',
                    },
                    {
                        customfield_id: customIDs.split_scheduled,
                        value: 'Yes',
                    },
                    {
                        customfield_id: customIDs.split_sch_current_date,
                        value: values?.scheduleDate ? currentTodayDateDate : '',
                    },
                    {
                        customfield_id: customIDs.split_type,
                        value: values?.type ? values?.type : '',
                    },
                    {
                        customfield_id: customIDs.split_current_po_taka,
                        value: '',
                    },
                    {
                        customfield_id: customIDs.split_new_po_taka,
                        value: '',
                    },
                    {
                        customfield_id: customIDs.split_new_po_quantity,
                        value: '',
                    },
                    {
                        customfield_id: customIDs.split_current_po_quantity,
                        value: '',
                    },
                ];
            } else if (values?.type === 'Partial') {
                new_custom_fields = [
                    {
                        customfield_id: customIDs.split_type,
                        value: values?.type ? values?.type : '',
                    },
                    {
                        customfield_id: customIDs.split_new_po_date,
                        value: values?.newPoDate ? newPODate : '',
                    },
                    {
                        customfield_id: customIDs.split_current_po_taka,
                        value: values?.currentPoQtyTaka
                            ? values?.currentPoQtyTaka
                            : '',
                    },
                    {
                        customfield_id: customIDs.split_new_po_taka,
                        value: values?.newPoQtyTaka ? values?.newPoQtyTaka : '',
                    },
                    {
                        customfield_id: customIDs.split_new_po_quantity,
                        value: values?.newPoQty ? values?.newPoQty : '',
                    },
                    {
                        customfield_id: customIDs.split_current_po_quantity,
                        value: values?.currentPoQty ? values?.currentPoQty : '',
                    },
                    {
                        customfield_id: customIDs.split_scheduled,
                        value: 'Yes',
                    },
                    {
                        customfield_id: customIDs.split_sch_current_date,
                        value: values?.scheduleDate ? currentTodayDateDate : '',
                    },
                    {
                        customfield_id: customIDs.split_full_po_date,
                        value: '',
                    },
                ];
            }
            const updateArray = [
                ...poOrder.custom_fields,
                ...new_custom_fields,
            ];
            console.log('updt', updateArray);

            dispatch(
                updatePurchaseOrderQuantity({
                    id: requestData.id,
                    custom_fields: updateArray,
                })
            );
            setShowSplitScheduleModal(false);
        },
    });

    useEffect(() => {
        if (id) {
            const fetchPOData = async () => {
                const data = await dispatch(fetchPurchaseOrder({ id: id }));
                const orderData = data?.payload?.purchaseorder;
                console.log('order data', orderData);
                if (orderData) {
                    setPoOrder(orderData);
                    formik.setValues({
                        scheduleDate: moment(
                            orderData?.custom_field_hash
                                ?.cf_splite_sch_current_date,
                            'DD/MM/YYYY'
                        )
                            .tz('Asia/Kolkata')
                            .toDate(),
                        type:
                            orderData?.custom_field_hash?.cf_splite_type || '',
                        currentPoNo:
                            orderData?.custom_field_hash?.cf_split_current_po ||
                            '',
                        fullPoDate: moment(
                            orderData?.custom_field_hash?.cf_split_full_po_date,
                            'DD/MM/YYYY'
                        )
                            .tz('Asia/Kolkata')
                            .toDate(),
                        currentPoQtyTaka:
                            orderData?.custom_field_hash
                                ?.cf_splite_current_po_taka || '',
                        currentPoQty:
                            orderData?.custom_field_hash
                                ?.cf_splite_current_po_quantity || '',
                        newPoDate: moment(
                            orderData?.custom_field_hash?.cf_split_new_po_date,
                            'DD/MM/YYYY'
                        )
                            .tz('Asia/Kolkata')
                            .toDate(),
                        newPoQtyTaka:
                            orderData?.custom_field_hash
                                ?.cf_splite_new_po_taka || '',
                        newPoQty:
                            orderData?.custom_field_hash
                                ?.cf_splite_new_po_quantity || '',
                    });
                } else {
                    setPoOrder(null);
                    // formik.resetForm({
                    //     values: {
                    //         scheduleDate: null,
                    //         type: '',
                    //         currentPoNo: '',
                    //         currentPoDate: null,
                    //         currentPoQtyTaka: '',
                    //         currentPoQty: '',
                    //         newPoDate: null,
                    //         newPoQtyTaka: '',
                    //         newPoQty: '',
                    //     },
                    // });
                }
            };
            fetchPOData();
        }
    }, [id]);

    useEffect(() => {
        if (formik?.values?.type === 'Partial' && poOrder) {
            formik.setFieldValue('currentPoNo', poOrder.purchaseorder_number);
            formik.setFieldValue('currentPoQty', poOrder.total_quantity);
            formik.setFieldValue(
                'currentPoQtyTaka',
                poOrder.custom_field_hash.cf_qty_taka
            );
        }
    }, [poOrder, formik?.values?.type]);

    return (
        <ModalBasic
            title={'Schedule Split'}
            modalOpen={showSplitScheduleModal}
            setModalOpen={setShowSplitScheduleModal}
        >
            {purchaseOrderLoading ? (
                <ClipLoader />
            ) : (
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col gap-2 p-4"
                >
                    <div className="flex flex-col md:flex-row gap-2">
                        <div className="w-full">
                            <FormikInputDateGroup
                                formik={formik}
                                label="Split Start Date"
                                name="scheduleDate"
                            />
                        </div>
                        <div className="w-full">
                            <FormikInputDateGroup
                                formik={formik}
                                label="Split End Date"
                                name="newPoDate"
                            />
                        </div>
                        <div className="w-full">
                            <FormikSelectGroup
                                formik={formik}
                                label="Type"
                                name="type"
                                options={PO_TYPE}
                            />
                        </div>
                    </div>
                    {formik?.values?.type === 'New' && (
                        <div className="flex flex-col gap-2">
                            <FormikInputDateGroup
                                formik={formik}
                                label="Full PO Date"
                                name="fullPoDate"
                            />
                        </div>
                    )}
                    {formik?.values?.type === 'Partial' && (
                        <div className="flex flex-col gap-2">
                            <FormikInputGroup
                                formik={formik}
                                label="Current PO"
                                name="currentPoNo"
                                readOnly
                            />
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="w-full">
                                    <FormikInputGroup
                                        formik={formik}
                                        label="Current PO Qty"
                                        name="currentPoQty"
                                    />
                                </div>
                                <div className="w-full">
                                    <FormikInputGroup
                                        formik={formik}
                                        label="Current PO Taka Qty"
                                        name="currentPoQtyTaka"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-2">
                                <div className="w-full">
                                    <FormikInputGroup
                                        formik={formik}
                                        label="New PO Qty"
                                        name="newPoQty"
                                    />
                                </div>
                                <div className="w-full">
                                    <FormikInputGroup
                                        formik={formik}
                                        label="New PO Taka"
                                        name="newPoQtyTaka"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="mt-4">
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    </div>
                </form>
            )}
        </ModalBasic>
    );
};

export default SplitScheduleModal;
