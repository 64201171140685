import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchPurchaseOrders,
    getPurchaseOrder,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { customIDs } from '../../utils/customIds';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import moment from 'moment';
import TableWithHeadingAndSearch from '../../components/Table/TableWithHeadingAndSearch';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { createCustomerPreference } from '../../app/reducers/CustomerPreference/customerPreferenceSlice';
import AttachmentModal from '../PurchaseOrder/AttachmentModal';
import { Edit2, X, Clock } from 'react-feather';
import { sassFalse } from 'sass';
import EditFollowUpDate from './EditFollowUpDate';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { ClipLoader, ClockLoader } from 'react-spinners';
import { openReminderPopUp } from '../../app/reducers/ReminderPopUp/reminderPopUpSlice';

function calculateFollowUpAccordingToDate(poArr) {
    const currentDate = moment().format('YYYY-MM-DD');
    const followUps = [];
    poArr?.forEach((item) => {
        if (
            moment(item.cf_po_followup_due_date_12d).isSameOrBefore(currentDate)
        ) {
            followUps.push(item);
        }
    });

    return followUps;
}

const FollowUp = () => {
    const { purchaseOrder, isLoading: loadingPOs } =
        useSelector(getPurchaseOrder);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(
            fetchPurchaseOrders({
                [`custom_field_${customIDs.purchase_order_followup_task}_contains`]:
                    'YES',
            })
        );
    }, []);
    // console.log("purchaseOrder", purchaseOrder?.purchaseorders)
    const purchaseOrdersArr = purchaseOrder?.purchaseorders;
    const followUps = calculateFollowUpAccordingToDate(purchaseOrdersArr);
    const [attachmentData, setAttachmentData] = useState(false);
    const [viewAttachmentModal, setAttachmentModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [dateModalOpen, setDateModalOpen] = useState(sassFalse);
    const [loadingReminder, setLoadingReminder] = useState(false);

    async function handlePairAndRating(row, star) {
        // const qualityRes = await dispatch(fetchQuality({ name: row.original.cf_quality_name }));
        // const QualityId = qualityRes?.payload?.data?.docs?.[0]._id
        // console.log(QualityId)
        await dispatch(
            createCustomerPreference({
                vendorName: row.original.vendor_name,
                customerName: row.original.cf_buyer_name,
                qualityName: row.original.cf_quality_name,
                star: star,
                purchaseOrder_id: row.original.purchaseorder_id,
            })
        );
        await dispatch(
            fetchPurchaseOrders({
                [`custom_field_${customIDs.purchase_order_followup_task}_contains`]:
                    'YES',
            })
        );
    }
    async function handleCancel(row, star) {
        // const qualityRes = await dispatch(fetchQuality({ name: row.original.cf_quality_name }));
        // const QualityId = qualityRes?.payload?.data?.docs?.[0]._id
        // console.log(QualityId)
        await dispatch(
            createCustomerPreference({
                vendorName: row.original.vendor_name,
                customerName: row.original.cf_buyer_name,
                qualityName: row.original.cf_quality_name,
                star: -1,
                purchaseOrder_id: row.original.purchaseorder_id,
            })
        );
        await dispatch(
            fetchPurchaseOrders({
                [`custom_field_${customIDs.purchase_order_followup_task}_contains`]:
                    'YES',
            })
        );
    }

    const FollowUpCols = [
        {
            Header: 'PURCHASE ORDER#',
            Cell: ({ row, data }) => {
                return (
                    <div
                        className={
                            row?.original?.has_attachment
                                ? 'underline text-blue-500 cursor-pointer'
                                : 'cursor-pointer'
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            setAttachmentData({
                                id: row.original.purchaseorder_id,
                                // page: page,
                            });
                            setAttachmentModal(true);
                        }}
                    >
                        {row.original.purchaseorder_number}

                        <span className="text-[0.5rem]">
                            {row?.original?.cf_last_c_uplod_dt}
                        </span>
                        {loadingReminder == row.original.purchaseorder_id ? (
                            <ClockLoader size={25} speedMultiplier={2} />
                        ) : (
                            <button
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    setLoadingReminder(
                                        row.original?.purchaseorder_id
                                    );
                                    console.log(row.original);
                                    let popUpData = {
                                        purchaseorder_number:
                                            row.original?.purchaseorder_number,
                                        cf_buyer_name:
                                            row.original?.cf_buyer_name,
                                        cf_quality_name:
                                            row.original?.cf_quality_name,
                                        cf_qty_taka: row.original?.cf_qty_taka,
                                        vendor_name: row.original?.vendor_name,
                                    };
                                    //Vendor Mobile No.
                                    const data = {
                                        id: row.original?.vendor_id,
                                    };
                                    const string = QueryString.stringify(data);
                                    const contacts =
                                        await authAxiosInstance.get(
                                            `purchase-orders/contacts/${data.id}?${string}`
                                        );
                                    if (contacts?.data?.data) {
                                        popUpData['vendorMobileNo'] =
                                            contacts?.data?.data?.contact?.contact_persons?.[0]?.mobile;
                                    }
                                    //Customer Mobile No.
                                    const customerData = {
                                        search: row.original?.cf_buyer_name,
                                    };
                                    const customerString =
                                        QueryString.stringify(customerData);
                                    const customerContacts =
                                        await authAxiosInstance.get(
                                            `purchase-orders/contacts?${customerString}`
                                        );
                                    console.log(customerContacts, 'new');
                                    if (customerContacts?.data?.data) {
                                        popUpData['customerMobileNo'] =
                                            customerContacts?.data?.data?.contacts?.[0]?.mobile;
                                    }
                                    dispatch(
                                        openReminderPopUp({
                                            isOpen: true,
                                            data: {
                                                ...popUpData,
                                                type: 'vendor',
                                            },
                                        })
                                    );
                                    setLoadingReminder('');
                                }}
                                className="ml-2"
                            >
                                <Clock
                                    size={25}
                                    color={
                                        row.original['cf_po_reminder'] ==
                                            'YES' ||
                                        row.original['cf_complaint_reminder'] ==
                                            'YES'
                                            ? '#ffff1a'
                                            : 'black'
                                    }
                                />
                            </button>
                        )}
                    </div>
                );
            },
            style: {
                width: '12%',
            },
        },
        // { Header: "SRNO", Cell: ({ row }) => row.index + 1 },
        { Header: 'QTY TAKA', accessor: 'cf_qty_taka', style: { width: '3%' } },
        { Header: 'Quality Name', accessor: 'cf_quality_name' },
        { Header: 'VENDOR NAME', accessor: 'vendor_name' },
        { Header: 'BUYER NAME', accessor: 'cf_buyer_name' },
        { Header: 'FOLLOW UP DATE', accessor: 'cf_po_followup_due_date_12d' },
        { Header: 'RATE', accessor: 'cf_rate', style: { width: '3%' } },
        // {
        //     Header: "DATE", Cell: ({ row }) => {
        //         return <>
        //             <p>{row.original?.date}</p>
        //             <p>{row.original?.due_by_days}</p>
        //         </>
        //     },
        //     accessor: "date"
        // },
        {
            Header: 'DATE',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col">
                        <span className="">
                            Issue Date{' '}
                            {moment(row.original.date).format('DD/MM/YYYY')}
                        </span>
                        <span>
                            Delivery Date{' '}
                            {moment(row.original.delivery_date).format(
                                'DD/MM/YYYY'
                            )}
                        </span>
                    </div>
                );
            },
            style: {
                width: '20%',
            },
        },
        // { Header: "DUE BY DAYS", accessor: "due_by_days" },
        {
            Header: 'AMOUNT',
            accessor: 'total',
            style: { width: '3%' },
        },
        {
            Header: 'RATING',
            Cell: ({ row }) => (
                <div className="flex gap-1 items-center ">
                    <PrimaryButton
                        onClick={async () => {
                            const star = 0;
                            handlePairAndRating(row, star);
                        }}
                    >
                        0
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={async () => {
                            const star = 3;
                            handlePairAndRating(row, star);
                        }}
                    >
                        3
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={async () => {
                            const star = 5;
                            handlePairAndRating(row, star);
                        }}
                    >
                        5
                    </PrimaryButton>
                    <Edit2
                        className="cursor-pointer"
                        onClick={(e) => {
                            {
                                e.preventDefault();
                                e.stopPropagation();
                                setDateModalOpen(true);
                                setEditData(row.original);
                            }
                        }}
                    />
                    <button
                        onClick={async () => {
                            const star = 5;
                            handleCancel(row, star);
                        }}
                    >
                        <X />
                    </button>
                </div>
            ),
        },
    ];

    const tableMemo = useMemo(() => {
        return (
            <TableWithHeadingAndSearch
                columns={FollowUpCols}
                data={followUps}
                heading={'Pending follow up'}
            />
        );
    }, [purchaseOrdersArr]);
    return (
        <PageWithCard heading="Follow Up">
            <AttachmentModal
                viewAttachmentModal={viewAttachmentModal}
                setAttachmentModal={setAttachmentModal}
                data={attachmentData}
            />
            <EditFollowUpDate
                setIsOpen={setDateModalOpen}
                isOpen={dateModalOpen}
                data={editData}
            />

            {/* <TableWithHeadingAndSearch
                columns={FollowUpCols}
                data={followUps}
                heading={'Pending follow up'}
            /> */}
            {tableMemo}
        </PageWithCard>
    );
};

export default FollowUp;
